import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';

import theme from 'Lib/theme';
import { initializeSentry } from 'Lib/utilities/sentry-utils';

import App from './containers/App';
import store from './store';

initializeSentry();

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <App />
        </ThemeProvider>
    </Provider>
);
